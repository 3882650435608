import React from "react";
import "./desgn1.css";
import Photo from "../Photo";
import {Link,I18nextContext,Trans} from "gatsby-plugin-react-i18next"
import { graphql } from "gatsby";
import { Helmet } from "react-helmet";
import { buildCanonicalUrl } from "../../utils/pages";



function formateDate(date) {
  let newDate = new Date(date * 1000).toDateString()
  return newDate
}

const Design1 = ({ data }) => {
  const { language } = React.useContext(I18nextContext)
  const canoncalUrl = buildCanonicalUrl(language, data)
  const nextPageUrl = `/blog/${data.frontmatter.slug}/`

  return (
    <div>
      <Helmet>
        <Link rel="canonical" href={canoncalUrl} />
      </Helmet>
      <div className="postPontainer">
        <div className="postCard">
          <div className="postPmage">
            <Link to={nextPageUrl}>
              <img
                src={data.frontmatter.img.childImageSharp.fluid.src}
                alt={data.frontmatter.title}
              />
            </Link>
          </div>
          <div className="postDate" lang={language}>
            <Trans>{formateDate(data.frontmatter.date)}</Trans>
          </div>

          <div className="postHeading" lang={language}>
            <Link to={nextPageUrl}>
              {data.frontmatter.title}
            </Link>
          </div>
          <div className="postContent" lang={language}>
            {data.frontmatter.desc}
          </div>
          <div className="postKnowMore">
            <Link to={nextPageUrl}>
              <Trans>Know More</Trans>
            </Link>
            <Photo
              src="KnowMore-Right Arrow-H12.svg"
              className="testing-arrow"
              alt=""
            />
          </div>
        </div>
      </div>
         
    </div>
  )
}

export default Design1;

export const query = graphql`
  query Mydesign($skip: Int!, $limit: Int!, $language: String!) {
    allMarkdownRemark(
      sort: { fields: frontmatter___date, order: DESC }
      filter: { frontmatter: { lang: { eq: $language } } }
      limit: $limit
      skip: $skip
    ) {
      nodes {
        html
        frontmatter {
          date
          desc
          slug
          title
          slug1
          Meta_Description
          Meta_Keywords
          img {
            childImageSharp {
              fluid(maxWidth: 360, maxHeight: 240) {
                src
              }
            }
          }
          featuredImg {
            childImageSharp {
              fluid {
                src
              }
            }
          }
          thumb {
            childImageSharp {
              fluid {
                src
              }
            }
          }
        }
      }
      pageInfo {
        currentPage
        hasNextPage
        hasPreviousPage
        perPage
        pageCount
        totalCount
      }
    }
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`

import React from "react"
import Header from "../../pages/header"
import Resources from "../common/resourcesdetails"
import Footer from "../common/footer"
import Layout from "../Layout"
import * as styles from "../../styles/pagestyles/indexmodules.css"
import Blogpost from "../BlogPost/design1"
import Photo from "../../components/Photo"
import { graphql } from "gatsby"
import { Link, I18nextContext, Trans } from "gatsby-plugin-react-i18next"
import { Helmet } from "react-helmet"

const GATSBY_LANDING_PAGE_URL = process.env.GATSBY_LANDING_PAGE_URL
const ResourcesTest = ({ data }) => {
  // const context = React.useContext(I18nextContext);
  const { nodes, pageInfo } = data.allMarkdownRemark
  const perviousPagePath = `${
    pageInfo.currentPage - 1 === 1
      ? "/blog"
      : `/blog/${pageInfo.currentPage - 1}`
  }`
  const nextPagePath = `/blog/${pageInfo.currentPage + 1}`

  const { language } = React.useContext(I18nextContext)
  const buildCanonicalUrl = () =>
    language === "en"
      ? pageInfo.currentPage === 1
        ? JSON.parse(JSON.stringify(GATSBY_LANDING_PAGE_URL + "/blog/"))
        : JSON.parse(JSON.stringify(GATSBY_LANDING_PAGE_URL + `/blog/${pageInfo.currentPage}/`))
        : JSON.parse(JSON.stringify(GATSBY_LANDING_PAGE_URL + `/${language}/blog/${pageInfo.currentPage}/`))
  const canoncalUrl = buildCanonicalUrl()

  const buildHrefUrl = () =>
    language === "en"
      ? JSON.parse(JSON.stringify(GATSBY_LANDING_PAGE_URL + "/"))
      : JSON.parse(JSON.stringify(GATSBY_LANDING_PAGE_URL +`/${language}/`))
  const hrefUrl = buildHrefUrl();

  return (
    <div className="Layout">
      <Header />
      <Resources />
      <Layout>
        <section
          className={["containerblogs", styles.blogs].join("")}
          lang={language}
        >
          {nodes.map((blog, index) => (
            <Blogpost key={index} data={blog} />
          ))}
        </section>
      </Layout>
      <Helmet>
        <Link rel="canonical" href={canoncalUrl} />
        <Link rel="alternate" href={hrefUrl} hreflang={language} />
      </Helmet>
      <div className="container">
        <div className="row">
          <div className="col-sm-6 perviouspagecolor" lang={language}>
            {pageInfo.hasPreviousPage && (
              <Link to={perviousPagePath + "/"}>
                <Trans>Previous Page</Trans>
                <Photo
                  src="KnowMore-Right Arrow-H12.svg"
                  className="testing-arrow-pervious"
                  alt=""
                />
              </Link>
            )}
          </div>
          <br></br>
          <div className="col-sm-6 text-right nextpagecolor" lang={language}>
            {pageInfo.hasNextPage && (
              <Link to={nextPagePath + "/"}>
                <Trans>Next Page</Trans>
                <Photo
                  src="KnowMore-Right Arrow-H12.svg"
                  className="testing-arrow"
                  alt=""
                />
              </Link>
            )}
          </div>
        </div>
      </div>
      <div className="end_divis_resources">
        <div className="header_contents_sections text-right"></div>
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <Photo src="Bottom Image-11.svg" className="innerimage" alt="" />
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default ResourcesTest
export const query = graphql`
  query testQuery($skip: Int, $limit: Int, $language: String) {
    allMarkdownRemark(
      sort: { fields: frontmatter___date, order: DESC }
      filter: { frontmatter: { lang: { eq: $language } } }
      limit: $limit
      skip: $skip
    ) {
      nodes {
        html
        frontmatter {
          date
          desc
          slug
          title

          img {
            childImageSharp {
              fluid(maxWidth: 360, maxHeight: 240) {
                src
              }
            }
          }
        }
      }
      pageInfo {
        currentPage
        hasNextPage
        hasPreviousPage
        perPage
        pageCount
        totalCount
      }
    }
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`

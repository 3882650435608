import React from "react";
import "../../styles/resources.scss";
import {Link, I18nextContext, Trans, useTranslation} from "gatsby-plugin-react-i18next";
import { Helmet } from "react-helmet";

const GATSBY_LANDING_PAGE_URL = process.env.GATSBY_LANDING_PAGE_URL
const Resourcesdetails = () => {
 
  const { language } = React.useContext(I18nextContext)
  const buildCanonicalUrl = () =>
    language === "en"
      ? JSON.parse(JSON.stringify(GATSBY_LANDING_PAGE_URL + "/blog/"))
      : JSON.parse(JSON.stringify(GATSBY_LANDING_PAGE_URL +`/${language}/blog/`))
  const canoncalUrl = buildCanonicalUrl();
  

  const buildHrefUrl = () =>
    language === "en"
      ? JSON.parse(JSON.stringify(GATSBY_LANDING_PAGE_URL + "/"))
      : JSON.parse(JSON.stringify(GATSBY_LANDING_PAGE_URL +`/${language}/`))
  const hrefUrl = buildHrefUrl();
  const { t } = useTranslation()

  return (
    <div>
      <Helmet>
        <script
          dangerouslySetInnerHTML={{
            __html: `
        
<!-- Google Tag Manager -->

        <script>(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','GTM-TK7M58Z');</script>
        
            `,
          }}
        />
        <script
          dangerouslySetInnerHTML={{
            __html: `
        
<!-- Google Tag Manager -->

        <noscript><iframe src="https://www.googletagmanager.com/ns.html?id=GTM-TK7M58Z"
        height="0" width="0" style="display:none;visibility:hidden"></iframe></noscript>    
            `,
          }}
        />
        <meta charSet="utf-8" />
        <title>{t("Logipulse Blogs - Latest Logistics News & Updates")}</title>
        <Link rel="canonical" href={canoncalUrl} />
        <Link rel="alternate" href={hrefUrl} hreflang={language} />

        <meta
          name="description"
          content={t(
            "Logipulse blogs are insights into the latest news, updates and trends in the shipping and logistics industry. Get going with Logipulse to stay updated in the freight forwarding segment."
          )}
        />
        <meta
          name="Keywords"
          content={t(
            "Logipulse blogs, Logistics trends and updates, Logistics news"
          )}
        />      
      </Helmet>
      <div className="banner-feature">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="content-imgs">
                <div
                  className="pricings_in_details resources_in_details"
                  lang={I18nextContext._currentValue.language}
                >
                  <Link to="/">
                    <Trans>HOME</Trans>
                  </Link>{" "}
                  /{" "}
                  <div className="featuress">
                    <Trans>RESOURCES</Trans>
                  </div>
                </div>
                <div
                  className="sub_main"
                  lang={I18nextContext._currentValue.language}
                >
                  <Trans>What's new in media.</Trans>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Resourcesdetails
export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
